import React from "react";
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import Layout3 from '../components/layout3';
import SEO from '../components/seo';

import Sticky from 'react-stickynode';
import Styles from './blog-post.module.css';

import Hanrei from "../containers/hanrei2"
// import Hanrei from "../containers/hanrei-kokudo"
import CesiumContainer from "../pages/cesiumContainer10ref"
import Image from '../components/image';
import PostCard from '../components/post-card/post-card';

const BlogPostTemplate = (props: any) => {
  const post = props.data.markdownRemark;

  const kml_kmz='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kmz'  
  const kml_kml='/kml/kokudo/'+post.frontmatter.no+'_2次メッシュ.kml'

  const { edges } = props.data.allMarkdownRemark;

  var filename="sdg_"+post.frontmatter.sdgs_no+".png";
  console.log(filename);
  
  return (
<Layout3>
      
  <SEO
    title={post.frontmatter.title}
    description={post.frontmatter.description || post.excerpt}
  />

    <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
      <div className={Styles.bar}>
        <div style={{display:"flex"}}>
          <div className={Styles.headerleft}>
            {/* <Cityset1 /> */}
            <a href={"/tags/sdgs-"+post.frontmatter.sdgs_no}>
              <Image filename={filename}/>
            </a>
          </div>
          <div className={Styles.headerno}>
            {post.frontmatter.no}
          </div>
          <div className={Styles.headertitle}>
            {post.frontmatter.title}
          </div>
          <div className={Styles.headertitle2}>
            {post.frontmatter.height_name+"×"+post.frontmatter.color_name}
          </div>
          <div className={Styles.headerright}>
            <a className={Styles.button} href="/top">HOME</a>
          </div>
        </div>
      </div>
    </Sticky>


    <div className={Styles.csstabgrid}>
      <div className={Styles.csstabgridinner}>
        <div className={Styles.csstabgridchild1}>
          <Link to={"/"+post.frontmatter.no+"/erabu"} className={Styles.dis2}>
          自分で地域を選択
          </Link>
        </div>
        <div className={Styles.csstabgridchild2}>
          <Link to={"/"+post.frontmatter.no+"/hikaku"} className={Styles.dis2}>
            2地域を比較
          </Link>
        </div>
      </div>
      <div className={Styles.csstabgridinner}>
        <div className={Styles.csstabgridchild3}>
          <Link to={"/"+post.frontmatter.no+"/tenkei"} className={Styles.dis2}>
            典型例と解説
          </Link>
        </div>
        <div className={Styles.csstabgridchild000}>
          <Link to={"/"+post.frontmatter.no+"/kokudo"} className={Styles.dis2}>
            国土像を俯瞰
          </Link>
        </div>
      </div>
    </div>

    <div className={Styles.cssgrid}>
      <div className={Styles.cssgridchild}>
        <div className={Styles.kakomitape4}>
          <span className={Styles.titletape4}>
            解説
          </span>
          <p>解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。解説の本文をここに入れます。</p>
        </div>

        <div className={Styles.hanrei}>
          <details open>
            <summary className={Styles.syousaiheader}>
            <h2>凡例</h2>
            </summary>
            <Hanrei
              frontmatter={post.frontmatter}
            />
          </details>
        </div>
      </div>

      <div className={Styles.cssgridchild}>
        <div>
          </div>
            <div className={Styles.cesiumcontainer}>
              <CesiumContainer
                kml_kml={kml_kml}
                kml_kmz={kml_kmz}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>



        <details>
          <summary className={Styles.syousaiheader}>
            <h2>データの詳細情報を確認する</h2>
          </summary>

<div className={Styles.csssyousaigridinner}>
  <div className={Styles.csssyousaigridchild000}>
    <div className={Styles.table}>
      <div>
        <h2>高さ</h2>
        <table style={{width:"100%"}}>
          <thead>
            <tr>
              <th className={Styles.row1}></th>
              <th className={Styles.row2}>解説</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>名称</td>
              <td>{post.frontmatter.height_name}</td>
              </tr>
              <tr>
              <td>年次</td>
              <td>{post.frontmatter.year_display}</td>
              </tr>
              <tr>
              <td>出典</td>
              <td>{post.frontmatter.height_origin}</td>
              </tr>
              <tr>
              <td>定義</td>
              <td>{post.frontmatter.height_origin_note}</td>
              </tr>
              <tr>
              <td>詳細</td>
              <td>
                <a href={post.frontmatter.height_origin_url}>{post.frontmatter.height_origin_url_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url2}>{post.frontmatter.height_origin_url2_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url3}>{post.frontmatter.height_origin_url3_name}</a>
                <br/>
                <a href={post.frontmatter.height_origin_url4}>{post.frontmatter.height_origin_url4_name}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>



  <div className={Styles.csssyousaigridchild000}>
    <div className={Styles.tablediv}>
      <div className={Styles.table}>
       <div>
          <h2>色</h2>
          <table>
          <thead>
          <tr>
          <th className={Styles.row1}></th>
          <th className={Styles.row2}>解説</th>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>名称</td>
          <td>{post.frontmatter.color_name}</td>
          </tr>
          <tr>
          <td>年次</td>
          <td>{post.frontmatter.year_display}</td>
          </tr>
          <tr>
          <td>出典</td>
          <td>{post.frontmatter.color_origin}</td>
          </tr>
          <tr>
          <td>定義</td>
          <td>{post.frontmatter.color_origin_note}</td>
          </tr>
          <tr>
          <td>詳細</td>
          <td>
            <a href={post.frontmatter.color_origin_url}>{post.frontmatter.color_origin_url_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url2}>{post.frontmatter.color_origin_url2_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url3}>{post.frontmatter.color_origin_url3_name}</a>
            <br/>
            <a href={post.frontmatter.color_origin_url4}>{post.frontmatter.color_origin_url4_name}</a>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
       </div>
      </div>
    </div>
  </div>

</details>


<details>
  <summary className={Styles.syousaiheader}>
      <h2>関連するデータを調べる</h2>
  </summary>

  <div className={Styles.csssyousaigridinner}>
    <div className={Styles.csssyousaigridchild000}>
      <div>
        <h2>
          タグ
        </h2>
      </div>
      <div>
        {post.frontmatter.tags == null ? null : (
        <div className={Styles.taglinks}>
          {post.frontmatter.tags.map((tag: string, index: number) => (
          <p>
            <Link
            key={index}
            to={`/tags/${_.kebabCase(tag)}/`}
            className={Styles.tag}
            >
              {`${tag}`}
            </Link>
          </p>
          ))}
        </div>
        )}
      </div>
    </div>

    <div className={Styles.csssyousaigridchild000}>
      <div>
        <h2>
          関連ページ
        </h2>
      </div>
      <div style={{display:"flex"}}>
        <div style={{margin:"0% 5% 0% 5%"}}>
            {edges.length !== 0 && (
          <div>
            {edges.map(({ node }: any) => (
            <div key={node.fields.slug}>
              <PostCard
                title={node.frontmatter.title || node.fields.slug}
                url={node.fields.slug}
                tags={node.frontmatter.tags}
                sdgs_no={node.frontmatter.sdgs_no}
                height_name={node.frontmatter.height_name}
                color_name={node.frontmatter.color_name}
              />
            </div>
            ))}
          </div>
          )}
        </div>
      </div>
    </div>
  </div>

</details>


</Layout3>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tag: [String!]) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fileAbsolutePath
      fields {
        slug
      }
      frontmatter {
        no
        title
        date(formatString: "DD MMM, YYYY")
        description
        tags
        sdgs_no
        sdgs_main
        sdgs_subcat
        kou_tisou_pt
        kou_tisou_chp
        kou_tisou_sec
        kou_tisou_sbsec
        kou_tisou_title
        kou_titan_pt
        kou_titan_chp
        kou_titan_sec
        kou_titan_sbsec
        kou_titan_title
        height_name
        height_origin
        height_origin_note
        height_origin_url_name
        height_origin_url
        height_origin_url2_name
        height_origin_url2
        height_origin_url3_name
        height_origin_url3
        height_origin_url4_name
        height_origin_url4
        color_name
        color_origin
        color_origin_note
        color_origin_url_name
        color_origin_url
        color_origin_url2_name
        color_origin_url2
        color_origin_url3_name
        color_origin_url3
        color_origin_url4_name
        color_origin_url4
        sokuchi
        mesh_size
        year_display
        honbun
        chyusyaku
        hosoku
        tags
        kml_file
        y202012
        y202101
        y202102
        description
        hanrei1_color
        hanrei2_color
        hanrei3_color
        hanrei4_color
        hanrei1_name
        hanrei2_name
        hanrei3_name
        hanrei4_name
        height_ratio
        kokudo_hanrei1_name
        kokudo_hanrei2_name
        kokudo_hanrei3_name
        kokudo_hanrei4_name
        kokudo_height_ratio
      }
    }
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tag } }
        fields: { slug: { ne: $slug } }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            no
            title
            tags

            sdgs_no
            sdgs_main
            sdgs_subcat
            kou_tisou_pt
            kou_tisou_chp
            kou_tisou_sec
            kou_tisou_sbsec
            kou_tisou_title
            kou_titan_pt
            kou_titan_chp
            kou_titan_sec
            kou_titan_sbsec
            kou_titan_title
            height_name
            height_origin
            height_origin_note
            height_origin_url_name
            height_origin_url
            height_origin_url2_name
            height_origin_url2
            height_origin_url3_name
            height_origin_url3
            height_origin_url4_name
            height_origin_url4
            color_name
            color_origin
            color_origin_note
            color_origin_url_name
            color_origin_url
            color_origin_url2_name
            color_origin_url2
            color_origin_url3_name
            color_origin_url3
            color_origin_url4_name
            color_origin_url4
            sokuchi
            mesh_size
            year_display
            honbun
            chyusyaku
            hosoku
            tags
            kml_file
            y202012
            y202101
            y202102
            description
            hanrei1_name
            hanrei1_color
            hanrei2_name
            hanrei2_color
            hanrei3_name
            hanrei3_color
            hanrei4_name
            hanrei4_color
            height_ratio
          }
        }
      }
    }
  }
`;

